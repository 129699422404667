html,body { 
	height: 100%;
  background-color: #f7f7f7;
}

.global-container{
  margin-top: 1%;
	padding-bottom: 1%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f7f7f7;
	margin-bottom: 1%;
}

form{
	padding-top: 10px;
	font-size: 14px;
	margin-top: 30px;
}

.card-title { 
	font-weight: 400;
	color: #282828;
}

.login-form{ 
	width:350px;
	margin:20px;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
  border-radius: 8px;
  border: 0px;
  box-shadow: 0px 1px 2px 1px #BDC3C7;
}

.form-control-sm {
	font-size: 12pt;
	padding: 1.2rem;
}

.alert{
	margin-bottom:-30px;
	font-size: 13px;
	margin-top:20px;
}

.button-disabled:disabled {
  background-color: #d0d0d0;
  border-color: #d0d0d0;
}

/* Animación para los inputs */

.input-field {
  position: relative;
  width: 250px;
  height: 80px;
  line-height: 44px;
  margin-left: 1%;
  margin-top: 3%;
}
.input-field p{
  color: #D45454;
  margin-top: -15px !important;
  font-size: 8pt;
}
.label {
  position: absolute;
  top: 17px;
  left: 10px;
  color: #84888b;
  transition: 0.15s all;
  cursor: text;
  font-size: 11pt;
  background-color: white;
  padding: 0px 7px;
  line-height: 1;
}
.input {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.1rem 0.7rem;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  font-size: 11pt;
}
.input:-webkit-autofill { 
    -webkit-box-shadow: 0 0 0 30px white inset; 
} 
.input:invalid {
  outline: 0;
}
.input_correcto:focus,
.input_correcto:valid {
  border: 2px solid #17a2b8;
  color: #3a3a3a;
}
.input_correcto:focus~label,
.input_correcto:valid~label {
  font-size: 10pt;
  top: -8px;
  color: #17a2b8;
}
.input_incorrecto:focus,
.input_incorrecto:valid {
  border: 2px solid #D45454;
  color: #3a3a3a;
}
.input_incorrecto:focus~label,
.input_incorrecto:valid~label {
  font-size: 10pt;
  top: -8px;
  color: #D45454;
}

/* Animación para los select */
.input-field-select {
  position: relative;
  width: 250px;
  height: 80px;
  line-height: 44px;
  margin-left: 1%;
  margin-top: 3%;
}
.label-select {
  position: absolute;
  top: 17px;
  left: 10px;
  color: #84888b;
  transition: 0.15s all;
  cursor: text;
  font-size: 11pt;
  background-color: white;
  padding: 0px 7px;
  line-height: 1;
}
.input-select {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.1rem 0.7rem;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  font-size: 11pt;
  height: 49px;
}
.input-select:-webkit-autofill { 
    -webkit-box-shadow: 0 0 0 30px white inset; 
} 
.input-select:invalid {
  outline: 0;
}

/* Animaciones tablas y estilo */

.filaTabla-hover:hover {
	background-color: #fafafa;
}

.filaTabla-hover:hover>td img {
  visibility: visible;
}

.filaTabla>td img {
  visibility: hidden;
}

.tablas-adm {
  padding: 0px;
}

.card-tablas-adm {
  padding: 0px;
}

.card-tablas-adm .row {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
}

th{
	font-size: 13px;
  font-weight: 400;
  color: #646464;
}
td {
	font-size: 14px;
  color: #0000008A;
}

/* Menú más en tabla */

.toggle-botonTabla:hover {
  filter: invert(0);
}

.toggle-botonTabla {
  margin: 0px;
  padding: 0px;
  width: 25px;
  float: right;
  cursor: pointer;
  filter: invert(0.5);
}

.itemDropdown:active {
  background-color: #17a2b8;
  color: white;
}

.itemDropdown {
  font-size: 11pt;
  color: #5f6368;
}

/* Barra de búsqueda */

.input-search:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.select-search:focus {
  box-shadow: none;
  border-color: #ccc;
}

.input-search {
  border: 0 ;
  border-bottom: 1px solid #cbcbcb ;
  width: 85% ;
  color: #333 ;
  background-color: transparent;
}

.select-search {
  width: 100%;
  height: 32px;
  font-size: 10pt;
  background-color: transparent;
}

.input-search::-webkit-input-placeholder { color: #ccc; } 

.input-search:-moz-placeholder { color: #ccc; } 

.input-search::-moz-placeholder { color: #ccc; } 

.input-search:-ms-input-placeholder { color: #ccc; }

/* Imagen de fondo Login */

.fondo-login {
  background-image: url('https://chiquifit.com.mx/wp-content/uploads/2021/01/chiquifit-00034.jpg');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 135%;
  -webkit-filter: opacity(40%) blur(4px);
  filter: opacity(40%) blur(4px);
}

/* Responsive del Login */

@media (max-width: 870px) {
  .card-login {
    position: absolute !important;
    height:100vh !important;
    width:100% !important;
    border-radius: 0px;
  }
  .f-login {
    display: block !important;
  }
  .fp-login {
    display: none;
  }
  .fondo-login {
    display: none;
  }
}

.card-login {
  width: 390px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 70px;
  border-radius: 5px;
  border: none;
}

/* Responsive de las tablas */

@media (max-width: 1125px) {
  .tablas-adm {
    width: 100% !important;
  }
}

/* Alert general -Eliminación-*/

.cancelButton {
  background-color: transparent !important;
  color: #5c5c5c !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.confirmButton {
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
}

.cancelButton:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.confirmButton:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.alert-buttons {
  flex-direction: row-reverse !important;
  padding-left: 50% !important;
}

.titulo-alert-instructores {
  margin-left: -174px !important;
}

.titulo-alert-administradores {
  margin-left: -134px !important;
}

.titulo-alert-responsables {
  margin-left: -155px !important;
}

.titulo-alert-alumnos {
  margin-left: -194px !important;
}

.titulo-alert-tutores {
  margin-left: -215px !important;
}

.titulo-alert-cursos {
  margin-left: -307px !important;
}

.titulo-alert-productos {
  margin-left: -274px !important;
}

.titulo-alert-logro {
  margin-left: -185px !important;
}

.container-alert {
  margin-bottom: -20px !important;
}
.container-alert p {
  text-align: left !important;
  margin-bottom: 10px !important;
}

@media (max-width: 600px) {
  .alert-buttons {
    padding-left: 0% !important;
  }
  .titulo-alert-instructores, .titulo-alert-administradores, .titulo-alert-responsables, .titulo-alert-alumnos, .titulo-alert-tutores, .titulo-alert-cursos, .titulo-alert-productos, .titulo-alert-logro {
    margin-left: 0px !important;
  }
  .container-alert p {
    text-align: center !important;
  }
}

/* Alert general -Update-*/

.titulo-alert-update {
  margin-right: 205px !important;
}

.titulo-alert-update-nombre {
  margin-right: 0px !important;
}

@media (max-width: 600px) {
  .titulo-alert-update, .titulo-alert-update-nombre {
    margin-right: 0px !important;
  }
}

/* Alert "loading" */

.titulo-alert-loading {
  display: none !important;
}

.alert-loader {
  filter: hue-rotate(-20deg) brightness(120%) !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

/* Botones sin sombra */

.boton-presionado:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/*------------- Direcciones -----------------*/

.principal-direcciones {
  width: 442px;
  overflow: hidden;
  border: 2px solid #17a2b8;
  padding: 20px !important;
  border-radius: 8px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 25px;
}
.label-principal-direcciones {
  position: absolute;
  background-color: white;
  padding: 0px 7px;
  line-height: 1;
  font-size: 11pt;
  color: #17a2b8;
  margin-top: -31px;
}
.input-field-address {
  position: relative;
  width: 388px;
  height: 70px;
  line-height: 44px;
  margin: auto;
  margin-bottom: 0px;
}
.input-field-address p{
  color: #D45454;
  margin-top: -15px !important;
  font-size: 8pt;
}
.input-address {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.1rem 0.7rem;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  box-shadow: none;
  color: white;
  font-size: 11pt;
}
.input-address:-webkit-autofill { 
    -webkit-box-shadow: 0 0 0 30px white inset; 
} 
.input-address:invalid {
  outline: 0;
}
.input_correcto-address:focus,
.input_correcto-address:valid {
  border: 2px solid #17a2b8;
  color: #3a3a3a;
}
.input_incorrecto-address:focus,
.input_incorrecto-address:valid {
  border: 2px solid #D45454;
  color: #3a3a3a;
}
.boton-agregar-ni {
  font-size: 10pt;
  color: #17a2b8;
  cursor: pointer;
  float: right;
  line-height: 28px;
  margin-bottom: 0px;
}
.boton-agregar-ni:hover {
  color: #1A7D8C;
}
#js-preview-map img {
  display:block;
  margin:auto;
}

/* Botón <p></p> */

.botonP {
  float: left;
  line-height: 2.4;
  color: #17a2b8;
  font-size: 10pt;
  text-decoration: none;
  cursor: pointer;
  padding: 0px 25px;
  border-radius: 5px;
}

/* Barra de búsqueda (formulario) */

.search {
  width: 100%;
  position: relative;
  display: flex;
}
.searchTerm {
  width: 100%;
  border: 2px solid #e6e6e6;
  background-color: #e6e6e6;
  border-right: none;
  padding: 5px;
  padding-left: 7px;
  height: 42px;
  border-radius: 8px 0 0 0px;
  outline: none;
  color: #3a3a3a;
}
.searchTerm:focus{
  color: #3a3a3a;
  border-color: #17a2b8;
  background-color: white;
}
.searchTerm:focus~button{
  border-color: #17a2b8;
  background-color: white;
  border-left: none;
}
.searchButton {
  width: 40px;
  height: 42px;
  border: 2px solid #e6e6e6;
  border-left: none;
  background: #e6e6e6;
  text-align: center;
  color: #696969;
  border-radius: 0 8px 0px 0;
  cursor: pointer;
  font-size: 20px;
}
.wrap{
  width: 435px;
  margin: auto;
}
.searchButton:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/* Botón imagen */

.botonImagen {
  opacity: 0.4;
  cursor: pointer;
}
.botonImagen:hover {
  opacity: 0.8;
}

/* Estilo registro de horario */

.div-dia {
  width: 200px;
  overflow: hidden;
  border: 1px solid #cbcbcb;
  padding: 20px !important;
  padding-top: 30px !important;
  padding-bottom: 0px !important;
  border-radius: 8px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: 0.15s all;
}
.div-dia-seleccionado {
  border: 2px solid #17a2b8;
}
.div-check-dias {
  position: absolute;
  background-color: white;
  padding: 0px 7px;
  line-height: 1;
  font-size: 11pt;
  color: #84888b;
  margin-top: -23px;
  transition: 0.15s all;
}
.div-check-dias label {
  margin-left: 5px;
}
.div-check-dias-seleccionado {
  margin-top: -40px;
  color: #17a2b8;
}

/* Grupo input radio */

.cont-grupo-radio {
  width: 100%;
  outline: 0;
  border-radius: 5px;
  box-shadow: none;
  font-size: 11pt;
  border: 1px solid #cbcbcb;
  color: #3a3a3a;
  padding-bottom: 3px;
}
.cont-grupo-radio label {
  font-size: 10.5pt;
  line-height: 1 !important;
  color: #84888b;
}
.label-grupo-radio {
  position: absolute;
  left: 10px;
  cursor: text;
  background-color: white;
  padding: 0px 7px;
  line-height: 1;
  font-size: 10pt;
  top: -8px;
  color: #84888b;
}

/* Alert eliminación cancelada */

.icono-error-small {
  margin: 0px !important;
  margin-bottom: 15px !important;
  width: 70px !important;
  height: 70px !important;
}

.titulo-alert-eliminar {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

/* Barra de búsqueda general de tablas */

.search-general {
  width: 100%;
  position: relative;
  display: flex;
}
.searchTerm-general {
  width: 100%;
  border: 2px solid #e6e6e6;
  background-color: #e6e6e6;
  border-right: none;
  padding: 5px;
  padding-left: 7px;
  height: 42px;
  border-radius: 8px 0px 0px 8px;
  outline: none;
  color: #3a3a3a;
  font-size: 10.7pt;
}
.searchTerm-general:focus{
  color: #3a3a3a;
  border-color: #17a2b8;
  background-color: white;
  font-weight: 400;
}
.searchTerm-general:focus~button{
  border-color: #17a2b8;
  background-color: white;
  border-left: none;
}
.searchButton-general {
  width: 40px;
  height: 42px;
  border: 2px solid #e6e6e6;
  border-left: none;
  background: #e6e6e6;
  text-align: center;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  font-size: 20px;
}
.wrap-general{
  width: 100%;
  margin: auto;
  margin-top: 4px;
}
.searchButton-general:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
.onSearch {
  background: #D0EDF2;
  border-color: #D0EDF2;
  color: #17a2b8;
  font-weight: 500;
}

/* INFORMACIÓN DEL ALUMNO */
.textoNormal {
  font-size: 14px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: #5f6368;
}
.textoBold {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.textoBoldSub {
  font-size: 13pt;
  font-weight: 500;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.textoEnlace {
  font-size: 14px;
  color: #17a2b8;
  cursor: pointer;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.textoEnlace:hover {
  color: #1EC0DA;
  text-decoration: none;
}
.borderTop {
  border-top: 1px solid #f2f2f2;
}
.borderBottom {
  border-bottom: 1px solid #f2f2f2;
}
.cardMenuInfo {
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}
.cardMenuInfo:hover p {
  color: #17a2b8;
}
.cardMenuInfo p{
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  color: #5f6368;
  cursor: pointer;
}
.cardMenuInfo-selected p{
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  color: #17a2b8;
  cursor: pointer;
}
.cardMenuInfo-selected div {
  border-bottom: 3px solid #17a2b8;
}
.cardMenuInfo-selected {
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}
.cambiarPassword:hover div p{
  color: #1EC0DA !important;
}
.cambiarPassword:hover img{
  filter: brightness(110%);
}
.pac-container {
  z-index:1070 !important;
}
.logout:hover img{
  opacity: 0.9 !important;
}
.circle {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.input-cantidad {
  width: 90%;
  border: 0;
  outline: 0;
  color: #0000008A;
  border: 1px solid #17a2b8;
  border-radius: 5px;
  box-shadow: none;
  font-size: 11pt;
}
.input-cantidad:-webkit-autofill { 
    -webkit-box-shadow: 0 0 0 30px white inset; 
} 
.input-cantidad:invalid {
  outline: 0;
  border: 1px solid #D45454;
}

/* [InfoAlumno]Selector de nivel - logros */
.label-nivel {
  font-size: 28px;
  color: #D4D9DA;
}
.label-nivel:hover,
.label-nivel:hover ~ label {
  color: #17a2b8;
}
.input-nivel{
  display: none;
}
.input-nivel:checked ~ label {
  color: #17a2b8;
}